import Home from "@/pages/Home"
import partnerImg  from "@/assets/img/partner_img.png"
import serviceImg  from "@/assets/img/service_img.png"
import DaystarImg  from "@/assets/img/Daystar_img.png"
import  daystarTitle from '@/assets/img/daystar_title.png'
import StrategyImg  from "@/assets/img/strategy_img.png"
import CooperateImg  from "@/assets/img/cooperate_img.png"

const bannerTitle =  "是由Gosun HK和iPollo战略支持的面向Web3、元宇宙、 \nAI等新兴行业的一站式全球性云资源服务商"
const language = 'zh'

const NdHome = ()=>{
    return (
        <Home
            bannerTitle={bannerTitle}
            language={language}
            partnerImg={partnerImg}
            serviceImg={serviceImg}
            DaystarImg={DaystarImg}
            daystarTitle={daystarTitle}
            CooperateImg={CooperateImg}
            StrategyImg={StrategyImg}
         />
    )
}
 
export default NdHome