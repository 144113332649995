import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import router from './router';
import { RouterProvider } from 'react-router-dom'
// import '@/assets/mobile/index.scss'
import { setRootFontSize } from '@/utils'

import 'lib-flexible'


setRootFontSize(); // 初始加载时执行一次
window.addEventListener('resize', setRootFontSize);


// import { setRootFontSize } from '@/utils'

  
//   // 初始化设置根元素字体大小
//   setRootFontSize();
  
//   // 监听窗口大小变化，动态更新根元素字体大小
//   window.addEventListener('resize', setRootFontSize);
//   window.addEventListener('DOMContentLoaded', setRootFontSize);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <RouterProvider router={router}></RouterProvider>
  // </React.StrictMode>
);

