
// import './index.scss'

import ndIcon from  "@/assets/img/nd_icon.png"
import serverImg from '@/assets/img/server.png'


import googleLogo  from "@/assets/img/google.png"
import aliLogo  from "@/assets/img/alibaba.png"
import awsLogo  from "@/assets/img/aws.png"
import huaweiLogo  from "@/assets/img/huawei.png"
import azureLogo  from "@/assets/img/azure.png"

import googleName  from "@/assets/img/Google_name.png"
import huaweiName  from "@/assets/img/Huawei_name.png"
import awsName  from "@/assets/img/AWS_name.png"
import azureName  from "@/assets/img/Azure_name.png"
import aliName  from "@/assets/img/Alibaba_name.png"


const partnerList = [
    { logo_img: googleLogo, nmae_img: googleName, 
        introduction:{
            zh:  'Google Cloud是由谷歌提供的一系列云计算服务，包括计算、存储、数据库、人工智能、机器学习和分析等功能。它基于谷歌自身在构建全球基础设施的经验和核心技术，为用户提供稳定、安全且高效的云端解决方案，其优势在于先进的数据分析和机器学习工具。' ,
            en: "Google Cloud is a suite of cloud computing services offered by Google, including computing, storage, databases, artificial intelligence, machine learning, and analytics. It is based on Google's own experience and core technologies in building global infrastructure, providing users with stable, secure, and efficient cloud solutions, and its advantages lie in advanced data analysis and machine learning tools."
        }
    },
    { logo_img: aliLogo, nmae_img: aliName, 
        introduction:{
            zh:   'Alibaba Cloud创立于2009年，是全球领先的云计算及人工智能科技公司，为200多个国家和地区的企业、开发者和政府机构提供服务，致力于以在线公共服务的方式，提供安全、可靠的计算和数据处理能力，让计算和人工智能成为普惠科技。Alibaba Cloud优势在于开放性和创新性，此外，Alibaba Cloud还拥有全球最大的云计算市场，客户可以在市场上找到各种各样的应用程序和解决方案。',
            en: "Alibaba Cloud was founded in 2009, a leading cloud computing and artificial intelligence technology company in the world, serving enterprises, developers, and government agencies in over 200 countries and regions. It is committed to providing safe and reliable computing and data processing capabilities in the form of online public services, making computing and artificial intelligence universally accessible technologies. The advantages of Alibaba Cloud lie in its openness and innovation."
        }
    },
    { logo_img: awsLogo, nmae_img: awsName, 
        introduction:{
            zh: 'AWS作为全球领先的云平台，提供广泛而全面的云服务，帮助数百万客户降低成本、提高敏捷性并加速创新。AWS的服务覆盖几乎所有行业和规模的客户，包括初创公司、大型企业和政府机构。其优势在于它的广泛性和成熟性。它提供了大量的服务，几乎覆盖了所有的云计算需求。',
            en: "As a global leading cloud platform, AWS provides a broad and comprehensive range of cloud services, helping millions of customers reduce costs, increase agility, and accelerate innovation. AWS services cover customers of almost all industries and scales, including startups, large enterprises, and government agencies, who has the advantage of extensiveness and maturity. It provides a large number of services, covering almost all cloud computing needs."
        } 
    },
    {logo_img:null,nmae_img:null, introduction:null},
    { logo_img: huaweiLogo, nmae_img: huaweiName, 
        introduction:{
           zh: 'Huawei Cloud成立于2005年，专注于云计算中公有云领域的技术研究与生态拓展，作为全球增速最快的主流云厂商之一，华为在全球部署30个区域、84个可用区，服务全球170多个国家和地区，拥有全球化团队和资源及世界级的安全技术和经验，可以为客户提供安全可靠的云服务。',
           en: "Huawei Cloud was established in 2005, focusing on technology research and ecosystem expansion in the public cloud field of cloud computing. As one of the fastest-growing mainstream cloud vendors in the world, Huawei has globally deployed 30 regions and 84 availability zones, serving over 170 countries and regions. It has a global team and resources, as well as world-class security technology and experience, providing customers with secure and reliable cloud services."
        }
    },
    { logo_img: azureLogo, nmae_img: azureName, 
        introduction: {
            zh: 'Azure由Microsoft在2010年正式推出。作为一家领先的云计算服务提供商，Azure提供了全球化的云计算基础设施，支持多种编程语言和框架。Azure可以让企业和开发者快速构建、部署和管理各种应用程序和服务，从而提高企业的效率和灵活性。其优势在于与Microsoft 的其他服务的深度集成。',
            en: "Azure was officially launched by Microsoft in 2010. As a leading cloud computing service provider, Azure provides a global cloud computing infrastructure, supporting multiple programming languages and frameworks. Azure allows enterprises and developers to quickly build, deploy, and manage various applications and services, thereby improving the efficiency and flexibility of enterprises. Its advantage lies in the deep integration with other Microsoft services."
        }
    }
]
   
function  PartnerItems( {logo_img,nmae_img,introduction,language} ){
    return (
        <div className='partner-item'>
            <img className='partner-logo' src={ logo_img} alt="" />
            <div className='partner-title'>
                <img className='partner-name' src={ nmae_img} alt="" />
                <img className='partner-icon' src={ ndIcon} alt="" />
            </div>
            <div className='partner-introduction'>
                { introduction[language] }
            </div>
        </div>
    )
}

function Partner({language,partnerImg}){
    return(
        <section className='main-partner'>
            <div className='container'>
                 <div className='partner-header'>
                    <img src={ partnerImg} alt="" />
                 </div>
                <div className='partner-content'>
                    <div className='partner-list'>
                        {
                            partnerList.map((item,index)=>{
                                // const introduction = item.introduction[language] || ''; // 根据语言选择相应的值
                                return (
                                    
                                     item.logo_img ?
                                      <PartnerItems 
                                        key={index} 
                                        logo_img={item.logo_img}
                                        nmae_img={item.nmae_img}
                                        introduction={item.introduction}
                                        language={language}
                                        />
                                    :(
                                        <div className='partner-img-inner' key={index} >
                                            <img src={ serverImg} alt="" />
                                        </div>
                                    )
                             
                                )
                            })
                        }
                   
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Partner