// import './index.scss'
 import googleDocs from '@/assets/img/google_docs.png'
 import txDocs from '@/assets/img/tx_docs.png'

 import  googleDocs1 from '@/assets/img/google_docs_1.png'
 import  txDocs1 from '@/assets/img/tx_docs_1.png'
 import  googleCuopor from '@/assets/img/google_cursor.png'
 import  txCuopor from '@/assets/img/tx_cursor.png'
 import cooperateBg from '@/assets/img/cooperate_bg.png'
 import cooperateMobileBg from '@/assets/img/h5/cooperate_bg.png'


 const items = [
    { img_url: googleDocs,file_url:googleDocs1,curpor_url:googleCuopor,link:'https://forms.gle/Lptcfg1PVJHtWRGy6'},
    { img_url: txDocs,file_url:txDocs1,curpor_url:txCuopor,link:'https://docs.qq.com/form/page/DSExrWHJwV3Vwak1t'},
 ] 
function CooperateItems({ img_url,file_url,curpor_url,link}){
    return (
        <div className="cooperate-item">
            <img className="cooperate-logo" src={ img_url} alt="" />
            <a href={link} target="_blank" rel="noreferrer" >
                <div className='cooperate-introduction'>
                    <img className="cooperate-file" src={ file_url} alt="" />
                    <img className="cooperate-curpor" src={ curpor_url} alt="" />
                    
                </div>
            </a>
        </div>
    )
}

function Cooperate({CooperateImg}){
    return(
       <section className="main-cooperate">
        {/* container-cooperate */}
            <div className='container'>
                 <div className='cooperate-header'>
                    <img src={ CooperateImg} alt="" />
                 </div>
                <div className='cooperate-content'>
                    <img className='cooperate-bg product-pc' src={ cooperateBg} alt="" />
                    <img className='cooperate-bg product-mobile' src={ cooperateMobileBg} alt="" />
                    
                    <div className="cooperate-list">
                        {
                            items.map((item,index)=>{
                                return(
                                     <CooperateItems
                                        key={index}
                                        img_url={item.img_url}
                                        file_url={item.file_url}
                                        link={item.link}
                                        curpor_url={item.curpor_url}
                                     />
                                   
                                )
                            })
                        }
                    </div>
                </div>
            </div>
       </section>
    )
}
export default Cooperate