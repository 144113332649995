// import './index.scss'
import  bannerLogo from '@/assets/img/banner_logo.png'
import  ndCloud from "@/assets/img/ND_cloud.png"
// import NumerCanvas from '@/components/NumerCanvas'
const Banner = ({bannerTitle})=>{
    return (
        <section className="main-banner">
            {/* <NumerCanvas/> */}
            <div className='container'>
                <div className='banner-content'>
                    <div className='banner-logo'>
                        <img src={ ndCloud} alt="" />
                    </div>
                    <div className='banner-title tc white-space'>
                    {/* 是由Gosun HK和iPollo战略支持的面向Web3、 元宇宙、
                <br/>AI等新兴行业的一站式全球性云资源服务商 */}
                    {bannerTitle}
                    </div>
                    <div className='ndcloud-img'>
                    <img src={ bannerLogo} alt="" />
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Banner