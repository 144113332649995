
//  import './index.scss'
 import strategyIcon from '@/assets/img/strategy_icon.png'

 import Web3 from '@/assets/img/WEB3.png'
 import Ipollo from '@/assets/img/Ipollo.png'
 import Metaera from '@/assets/img/Metaera.png'
 import Gosun from '@/assets/img/Gosun.png'
 import Ndlabs from '@/assets/img/Ndlabs.png'
 import ABGAImg from '@/assets/img/ABGA.png'

 const items = [
    // {img_url:null},
    { img_url: Ipollo},
    { img_url: Gosun},
    { img_url: Ndlabs},
    { img_url: Metaera},
    { img_url: ABGAImg},
    { img_url: Web3}
 ] 
function CooperateItems({ img_url,file_url,curpor_url}){
    return (
        <div className="strategy-item">
            <img className="strategy-logo" src={ img_url} alt="" />
        </div>
    )
}

function Strategy({StrategyImg}){
    return(
       <section className="main-strategy">
        {/* container-strategy */}
            <div className='container'>
                 <div className='strategy-header'>
                    <img src={ StrategyImg} alt="" />
                 </div>
                <div className='strategy-content'>
                    <div className='strategy-wrap'>

                        {
                            [1,2,3,4,5].map((item,index)=>{
                                return  <img key={item} className={`strategy-icon strategy-icon${index+1}` } src={ strategyIcon} alt="" />
                            })
                        }
                        <div className="strategy-list">
                            {
                                items.map((item,index)=>{
                                    return(
                                        <CooperateItems
                                            key={index}
                                            img_url={item.img_url}
                                        />
                                    
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
       </section>
    )
}
export default Strategy