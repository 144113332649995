import './index.scss'
import productLogo from '@/assets/img/product/product_img.png'
import productName from '@/assets/img/product/product_name.png'
import productIcon from '@/assets/img/product/product_icon1.png'

// 标题
import productTitle1 from '@/assets/img/product/product_title1.png'
import productTitle2 from '@/assets/img/product/product_title2.png'
import productTitle3 from '@/assets/img/product/product_title3.png'
import productTitle4 from '@/assets/img/product/product_title4.png'
import productTitle5 from '@/assets/img/product/product_title5.png'
// service 网络 内容

// map 地图
import mapImg from '@/assets/img/product/product_map.png'

import serviceImg from '@/assets/img/product/product_service.png'

import ProductMobile from './mobile'

function Product(){
    return(
        <section className="main-product">
             <div className='container product-pc'>
                <div className='product-header'>
                    <img src={ productLogo} alt="" />
                </div>
                <div className='product-content'>
                    <div className='product-name'>
                        <img className='name' src={ productName} alt="" />
                        <img className='hr' src={ productIcon} alt="" />
                    </div>
                    <div className='product-wrap'>
                        <div className='product-title'>
                            <img className='name' src={ productTitle1} alt="" />
                        </div>
                        <div className='product-wrap-body'>
                           <div className='product-block'>
                                <div className='product-block-1'>
                                    CDN平台10年+运营经验
                                </div>
                                <div className='product-block-2'>
                                    全国CDN业务牌照
                                </div>
                                <div className='product-block-3'>
                                    在全球范围内提供最佳融合CDN服务
                                </div>
                           </div>
                           <div className='product-block'>
                                <div className='product-block-4'>
                                    提供点/直播、页面下载类型加速
                                </div>
                                <div className='product-block-5'>
                                    国内资源节点2000+
                                </div>
                                <div className='product-block-6'>
                                    海外资源节点800+
                                </div>
                           </div>
                           <div className='product-block'>
                                <div className='product-block-7'>
                                    海量带宽储备，带宽扩容无瓶颈
                                </div>
                                <div className='product-block-8'>
                                    价格优势明显，极致性价比
                                </div>
                           </div>
                           <div className='product-block flex-start'>
                                <div className='product-block-9'>
                                    国内-自建/融合：华为云/天翼云/网宿/阿里云/腾讯云...
                                </div>
                           </div>
                           <div className='product-block flex-end'>
                                <div className='product-block-10'>
                                    海外-融合：Google Cloud/Akamai/CLOUDFLARE/Limelight...
                                </div>
                           </div>
                        </div>
                    </div>
                    <div className='product-wrap'>
                        <div className='product-title product-title2'>
                            <img className='name' src={ productTitle2} alt="" />
                        </div>
                        <div className='map'>
                                <img  src={ mapImg} alt="" />
                            </div>
                        <div className='product-wrap-body'>
                            <p className='product-text'>
                            200+分布式IDC节点，丰富的机柜资源，覆盖了国内大部分省市的主流运营商，包括：电信、联通、移动和教育网等
                             <br/>
                            全方位、跨平台、跨地区提供分布式IDC/BGP等完整解决方案
                            </p>
                        </div>
                    </div>
                    <div className='product-wrap'>
                        <div className='product-title product-title3'>
                            <img className='name' src={ productTitle3} alt="" />
                        </div>
                        <div className='product-wrap-body'>
                            <p className='product-text'>
                            覆盖109个城市的大容量IP通信服务平台，4T+数据传送能力<br/>
                            云专线服务，高速、稳定、安全的专属连接通道，充分利用公有云服务优势的同时，继续使用现有的IT设施，<br/>
                            实现灵活一体，可伸缩的混合云计算环境<br/>
                            SD-WAN服务,根据业务意图构建网络满足灵活多样的业务需求,比传统MPLS广域网专线降低50%以上成本

                            </p>
                        </div>
                    </div>
                    <div className='product-wrap'>
                        <div className='product-title product-title4'>
                            <img className='name' src={ productTitle4} alt="" />
                        </div>
                        <div className='product-wrap-body'>
                            <p className='product-text'>
                            助力企业用户上好云、用好云、管好云<br/>
                            全生命周期管理服务：咨询规划/迁移服务/运维管理服务/云上持续优化<br/>
                            云代理服务：合作伙伴招募/高返佣政策/全程服务支持<br/>
                            云专线服务：上云专线/多云互联专线/交付周期短/与国内外各大公有云平台实现互联<br/>
                            增值服务：云安全（DDoS高防、等级保护）/云通信（云短信、物联网IOT）/云会议

                            </p>
                        </div>
                    </div>
                    <div className='product-wrap'>
                        <div className='product-title product-title5'>
                            <img className='name' src={ productTitle5} alt="" />
                        </div>
                        <div className='product-wrap-body flex-wrap' >
                            <div className='text-content'>
                                <p className='product-text'>
                                ND Cloud裸金属：无虚拟化性能损耗、独享服务器计算能力和存储空间，满足核心应用场景对高性能、稳定性和安全性的需求<br/>
                                GPU算力：提供A100/A800/H800等多种GPU卡型，为AI、高性能计算、渲染等场景打造功能强大的可扩展GPU加速服务平台<br/>
                                一站式运维管理外包：为客户承担对应的IT服务质量和管理绩效，带动客户全面提升IT的整体服务管理水平

                                </p>
                            </div>
                            <div className='product-service'>
                                <img className='name' src={ serviceImg} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
             </div>
             <ProductMobile />
        </section>

    )
}
export default Product