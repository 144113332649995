// import './mobile.scss'
import productLogo from '@/assets/img/h5/product_img.png'
import productName from '@/assets/img/product/product_name.png'
import productIcon from '@/assets/img/h5/product_icon1.png'

// 标题
import producticons1 from '@/assets/img/h5/product_icons1.png'
import producticons2 from '@/assets/img/h5/product_icons2.png'
import producticons3 from '@/assets/img/h5/product_icons3.png'
import producticons4 from '@/assets/img/h5/product_icons4.png'
import producticons5 from '@/assets/img/h5/product_icons5.png'
// service 网络 内容

// map 地图
import mapImg from '@/assets/img/product/product_map.png'

import serviceImg from '@/assets/img/en/product_service.png'


function ProductMobile(){
    return(
            <div className='container product-mobile'>
            <div className='product-mobile-header'>
                <img src={ productLogo} alt="" />
            </div>
            <div className='product-mobile-content'>
                <div className='product-mobile-name'>
                    <img className='name' src={ productName} alt="" />
                    <img className='hr' src={ productIcon} alt="" />
                </div>
                <div className='product-mobile-wrap'>
                    <div className='product-mobile-title'>
                        <img className='name' src={ producticons1} alt="" />
                    </div>
                    <div className='product-mobile-wrap-body'>
                        <div className='product-mobile-block product-mobile-blocks1'>
                            <div className='product-mobile-block-1'>
                                CDN平台10年+运营经验
                            </div>
                            <div className='product-mobile-block-2'>
                                全国CDN业务牌照
                            </div>
                            <div className='product-mobile-block-3'>
                                国内资源节点2000+
                            </div>
                        </div>
                        <div className='product-mobile-block product-mobile-blocks2'>
                        <div className='product-mobile-block-4'>
                                在全球范围内提供最佳融合CDN服务
                            </div>
                            <div className='product-mobile-block-5'>
                                价格优势明显，极致性价比
                            </div>
                        </div>
                        <div className='product-mobile-block product-mobile-blocks3'>
                            <div className='product-mobile-block-6'>
                                提供点/直播、页面下载类型加速
                            </div>
                            <div className='product-mobile-block-7'>
                                海量带宽储备，带宽扩容无瓶颈
                            </div>
                        </div>
                        <div className='product-mobile-block  product-mobile-blocks4'>
                            <div className='product-mobile-block-8'>
                                国内-自建/融合：华为云/天翼云/网宿/阿里云/腾讯云...
                            </div>
                        </div>
                        <div className='product-mobile-block product-mobile-blocks5'>
                            <div className='product-mobile-block-9'>
                                海外-融合：Google Cloud/Akamai/CLOUDFLARE/Limelight...
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-mobile-wrap'>
                    <div className='product-mobile-title product-mobile-title2'>
                        <img className='name' src={ producticons2} alt="" />
                    </div>
                    <div className='map'>
                            <img  src={ mapImg} alt="" />
                        </div>
                    <div className='product-mobile-wrap-body'>
                        <p className='product-mobile-text'>
                        200+分布式IDC节点，丰富的机柜资源，覆盖了国内大部分省市的主流运营商，包括：电信、联通、移动和教育网等
                            <br/>
                        全方位、跨平台、跨地区提供分布式IDC/BGP等完整解决方案
                        </p>
                    </div>
                </div>
                <div className='product-mobile-wrap'>
                    <div className='product-mobile-title product-mobile-title3'>
                        <img className='name' src={ producticons3} alt="" />
                    </div>
                    <div className='product-mobile-wrap-body'>
                        <p className='product-mobile-text'>
                        覆盖109个城市的大容量IP通信服务平台，4T+数据传送能力<br/>
                        云专线服务，高速、稳定、安全的专属连接通道，充分利用公有云服务优势的同时，继续使用现有的IT设施，<br/>
                        实现灵活一体，可伸缩的混合云计算环境<br/>
                        SD-WAN服务,根据业务意图构建网络满足灵活多样的业务需求,比传统MPLS广域网专线降低50%以上成本

                        </p>
                    </div>
                </div>
                <div className='product-mobile-wrap'>
                    <div className='product-mobile-title product-mobile-title4'>
                        <img className='name' src={ producticons4} alt="" />
                    </div>
                    <div className='product-mobile-wrap-body'>
                        <p className='product-mobile-text'>
                        助力企业用户上好云、用好云、管好云<br/>
                        全生命周期管理服务：咨询规划/迁移服务/运维管理服务/云上持续优化<br/>
                        云代理服务：合作伙伴招募/高返佣政策/全程服务支持<br/>
                        云专线服务：上云专线/多云互联专线/交付周期短/与国内外各大公有云平台实现互联<br/>
                        增值服务：云安全（DDoS高防、等级保护）/云通信（云短信、物联网IOT）/云会议

                        </p>
                    </div>
                </div>
                <div className='product-mobile-wrap'>
                    <div className='product-mobile-title product-mobile-title5'>
                        <img className='name' src={ producticons5} alt="" />
                    </div>
                    <div className='product-mobile-wrap-body flex-wrap' >
                        <div className='text-content'>
                            <p className='product-mobile-text'>
                            ND Cloud裸金属：无虚拟化性能损耗、独享服务器计算能力和存储空间，满足核心应用场景对高性能、稳定性和安全性的需求<br/>
                            GPU算力：提供A100/A800/H800等多种GPU卡型，为AI、高性能计算、渲染等场景打造功能强大的可扩展GPU加速服务平台<br/>
                            一站式运维管理外包：为客户承担对应的IT服务质量和管理绩效，带动客户全面提升IT的整体服务管理水平

                            </p>
                        </div>
                        <div className='product-mobile-service'>
                            <img className='name' src={ serviceImg} alt="" />
                        </div>
                    </div>
                </div>
                
            </div>
            </div>
    )
}
export default ProductMobile