import NdLayout from "@/components/NdLayout"
import './index.scss'
const items= [
    {
       label: '首页',
      key: '/'
    },
    {
       label: '产品服务',
      key: '/product'

    },
    { 
      label: '关于我们',
      key: '/about'
    },
  ]


const CnLayout = ()=>{
    return (
        <NdLayout NdLanguage="zh" items={items} />
    )   
}

export default CnLayout