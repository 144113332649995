import './index.scss'

import Partner from './components/Partner'
import GlobalService from './components/GlobalService'
import Daystar from './components/Daystar'
import Cooperate from './components/Cooperate'
import Strategy from './components/Strategy'
import MainBanner from './components/Banner'

// import NumerCanvas from '@/components/NumerCanvas'

// import partnerImg  from "@/assets/img/partner_img.png"
// const bannerTitle =  "是由Gosun HK和iPollo战略支持的面向Web3、 \n元宇宙、AI等新兴行业的一站式全球性云资源服务商"
// const language ='zh'
function Home({language,bannerTitle,partnerImg,serviceImg,DaystarImg,daystarTitle,CooperateImg,StrategyImg}){
    return(
        <>
        {/* 头部图片 */}
        <MainBanner  bannerTitle={bannerTitle} />
        {/* 合作伙伴 */}
        <Partner language={language} partnerImg={partnerImg} />
        {/* 全球服务 */}
        <GlobalService language={language} serviceImg={serviceImg}/>
        {/* 启明星计划 */}
        <Daystar language={language} DaystarImg={DaystarImg} daystarTitle={daystarTitle}/>
        {/* 合作申请 */}
        <Cooperate CooperateImg={CooperateImg} />
        {/* 战略合作伙伴 */}
        <Strategy StrategyImg={StrategyImg}/>
        </>
    )
}
export default Home