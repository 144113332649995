// 查看当前是哪个背景图
export const findBgBykey = (key,items) => {
    const item = items.find((item) => item.key === key);
    return item ? item.bg : null;
};
// 适配视窗大小
export const  setRootFontSize =()=> {
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (window.innerWidth < 780 || isMobile ) {
      import('@/assets/mobile/index.scss');
    }
}