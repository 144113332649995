import { Layout } from 'antd'
import classNames from 'classnames'
import '@/assets/css/style.css'
import { Outlet,Link,useLocation } from 'react-router-dom'
import { useState,useEffect } from 'react'
import MenuLayout from '../MenuLayout'

import twitterLogo from '@/assets/img/x.png'
import tegegrateLogo from '@/assets/img/tegegrate.png'
// import homeBgImg from   '@/assets/img/bg.png'
// import aboutBgIng from '@/assets/img/about/about_bg.png'
import languageZh from '@/assets/img/language_zh.png'
import languageEn from '@/assets/img/language_en.png'

const { Header,Footer } = Layout

const bgMapClass = {
  '/':'home-layout',
  '/product':'product-layout',
  '/about':'about-layout'

}
const bgEnMapClass = {
  '/en':'home-layout',
  '/en/product':'product-layout',
  '/en/about':'about-layout'

}

function NdLayout({NdLanguage,items}){
  // 获取当前的路由值
  const location = useLocation()
  const currentPage = location.pathname
  // 滚动图片隐藏头部
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);


  useEffect(() => {
    const handleScroll = () => {
      // 检查页面滚动位置，决定是否隐藏头部
      const shouldHideHeader = window.scrollY > 100; // 100 是滚动的阈值

      // 更新状态，触发重新渲染
      setIsHeaderVisible(!shouldHideHeader);
    };

    // 监听页面滚动事件
    window.addEventListener('scroll', handleScroll);

    // 清除监听器以避免内存泄漏
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
   console.log( '当前语言',NdLanguage)

    return(
      
        // <Layout className={classNames( NdLanguage ==='zh' ? 'cn-font-family':'en-font-family','web-root' )} style={{ backgroundImage: `url(${backgroundImage})`}}>
        <Layout className={classNames( NdLanguage ==='zh' ? `cn-font-family ${bgMapClass[currentPage]} `:`en-font-family ${bgEnMapClass[currentPage]}`,'web-root' ) } >
          <Header className={ classNames('header header-absoulate',isHeaderVisible ? 'header-show' : 'header-hide') } >
              <div className='container '>
                <div className='row'>
                  <Link to={ NdLanguage ==='en' ?'/en' : '/' }>
                    <div className="logo" />
                  </Link>
                  <div className='df-flex'>
                    <MenuLayout items={items} currentPage={currentPage} />
                      <Link to={ NdLanguage ==='zh' ?'/en' : '/' } >

                        <img src={  NdLanguage ==='zh' ? languageEn :languageZh } alt='' />
                      </Link>
                  </div>
               
                  </div>
              </div>
            </Header>
            <div className='page-wrapper'>
                {/* 子元素占位符 */}
                <Outlet />
            </div>
            <Footer>
              <div className='container '>
                <div className='row'>
                  <div className='display-flex'>
                    <Link to={ NdLanguage ==='en' ?'/en' : '/' }>
                      <div className="logo" />
                    </Link>
                    <span>© 2023 </span>
                  </div>
               
                  <MenuLayout items={items} currentPage={currentPage}  />
                  </div>
                  <div className='row'>
                    <span className='link-block'></span>
                    <div className='link-list'>
                      <a href="https://www.t.me/NDCloudio" target="_blank" rel="noopener noreferrer">
                        <img src={tegegrateLogo} alt="tegegrateLogo"/>
                      </a>
                      <a href="https://twitter.com/NDCloudio" target="_blank" rel="noopener noreferrer">
                        <img src={twitterLogo} alt="twitterLogo"/>
                      </a>
                    </div>
                  </div>
              </div>
         
            </Footer>
        </Layout>
    )
}
export default NdLayout