import aboutLogo from '@/assets/img/about/about_img.png'
import About from '@/pages/About'

const language = "zh"

const NdAbout = ()=>{
    return (
        <About language={language} aboutLogo={aboutLogo} />
    )
}
export default NdAbout