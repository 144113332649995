import './index.scss'
import productLogo from '@/assets/img/en/product_img.png'
import productIcon from '@/assets/img/product/product_icon1.png'

import productMobileIcon from '@/assets/img/en/h5/product_icon1.png'

import mapImg from '@/assets/img/product/product_map.png'

import serviceImg from '@/assets/img/en/product_service.png'

import serviceMobileImg from '@/assets/img/en/h5/product_service.png'

import productIcons1 from '@/assets/img/en/product_icons1.png'
import productIcons2 from '@/assets/img/en/product_icons2.png'
import productIcons3 from '@/assets/img/en/product_icons3.png'
import productIcons4 from '@/assets/img/en/product_icons4.png'
import productIcons5 from '@/assets/img/en/product_icons5.png'

// import NdProductMobile  from './mobile'


function NdProduct(){
    return(
        <section className="main-product">
             <div className='container'>
                <div className='product-en-header'>
                    <img src={ productLogo} alt="" />
                </div>
                
                {/* <div className='product-mobile-en-header hideMobile'>
                    <img src={ productH5Logo} alt="" />
                </div> */}
                <div className='product-en-content'>
                    <div className='product-en-name'>
                        <div  className='name'>
                        Five major business areas, empowering digital transformation
                        </div>
                        {/* <img className='name' src={ productName} alt="" /> */}
                        <img className='hr showMobile'  src={ productIcon} alt="" />
                        <img  className='hr hideMobile'  src={ productMobileIcon} alt="" />
                    </div>
                    <div className='product-en-wrap'>
                        <div className='product-en-title'>
                            <img   src={ productIcons1} alt="" />
                            {/* <img className='name' src={ productTitle1} alt="" /> */}
                        </div>
                        <div className='product-en-wrap-body showMobile'>
                           <div className='product-en-block'>
                                <div className='product-en-block-1'>
                                Over 10 years of CDN platform operation experience
                                </div>
                                <div className='product-en-block-2'>
                                National CDN business license
                                </div>
                           </div>
                           <div className='product-en-block'>
                                <div className='product-en-block-3'>
                                Providing the best integrated CDN service worldwide
                                </div>
                                <div className='product-en-block-4'>
                                Over 2000 domestic resource nodes
                                </div>
                           </div>
                           <div className='product-en-block'>
                                <div className='product-en-block-5'>
                                Provides acceleration for point/live broadcast, webpage download+
                                </div>
                                <div className='product-en-block-6'>
                                Over 800 overseas resource nodes+
                                </div>
                           </div>
                           <div className='product-en-block flex-start'>
                                <div className='product-en-block-7'>
                                Massive bandwidth reserves, no bottleneck in bandwidth expansion
                                </div>
                           </div>
                           <div className='product-en-block flex-start'>
                            <div className='product-en-block-8'>
                                Superior in quality and lowcost, ultimate cost-effectiveness
                                </div>
                           </div> 
                           <div className='product-en-block flex-end'>
                                <div className='product-en-block-9'>
                                Overseas-Integrated: Google Cloud /Akamai/CLOUDFLARE/Limelight...
                                </div>
                           </div>
                           <div className='product-en-block flex-start'>
                                <div className='product-en-block-10'>
                                Domestic Self-built/Integrated: Huawei Cloud/E Cloud/Wangsu Cloud/Alibaba Cloud/Tencent Cloud...

                                </div>
                           </div>
                        </div>
                        <div className='product-mobile-en-wrap-body hideMobile'>
                           <div className='product-en-block product-en-blocks1'>
                                <div >
                                Over 10 years of CDN platform operation experience
                                </div>
                              
                           </div>
                           <div className='product-en-block product-en-blocks2'>
                                <div >
                                    National CDN business license
                                </div>
                                <div >
                                    Over 2000 domestic resource nodes
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks3'>
                                <div >
                                    Over 800 overseas resource nodes
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks4'>
                                <div >
                                    Provides acceleration for point/live broadcast, webpage download
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks5'>
                                <div >
                                    Massive bandwidth reserves, no bottleneck in bandwidth expansion
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks6'>
                                <div >
                                    Superior in quality and lowcost, ultimate cost-effectiveness
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks7'>
                                <div >
                                    Overseas-Integrated: Google Cloud /Akamai/CLOUDFLARE/Limelight...
                                </div>
                           </div>
                           <div className='product-en-block product-en-blocks8'>
                                <div >
                                Domestic Self-built/Integrated: Huawei Cloud/E Cloud/Wangsu Cloud/<br/>
                                Alibaba Cloud/Tencent Cloud...
                                </div>
                           
                           </div>
                           
                        </div>
                    </div>
                    <div className='product-en-wrap'>
                        <div className='product-en-title'>
                            <img  src={ productIcons2} alt="" />
                        </div>
                        <div className='map'>
                                <img  src={ mapImg} alt="" />
                            </div>
                        <div className='product-en-wrap-body'>
                            <p className='product-en-text'>
                            200+ distributed IDC nodes, rich rack resources, covering the mainstream operators in most provinces and cities in China, including: Telecom, Unicom, Mobile and Education Network, etc.
                              A complete solution for distributed IDC/BGP, etc., is provided in all-round, cross-platform, and cross-regional manners.
                            </p>
                        </div>
                    </div>
                    <div className='product-en-wrap'>
                        <div className='product-en-title'>
                            <img  src={ productIcons3} alt="" />
                            {/* <img className='name' src={ productTitle3} alt="" /> */}
                        </div>
                        <div className='product-en-wrap-body'>
                            <p className='product-en-text'>
                            A large-capacity IP communication service platform covering 109 cities, with 4T+ data transmission capabilities.<br/>
Cloud dedicated line services provide high-speed, stable, and secure exclusive connection channels. While fully utilizing the advantages of public cloud services, continue to use existing IT facilities to create a flexible, scalable hybrid cloud computing environment.
SD-WAN service, build a network according to business intent to meet diverse business needs, reducing the cost by more than 50% compared to the traditional MPLS Wide Area Network.
                            </p>
                        </div>
                    </div>
                    <div className='product-en-wrap'>
                        <div className='product-en-title'>
                            <img  src={ productIcons4} alt="" />
                            {/* <img className='name' src={ productTitle4} alt="" /> */}
                        </div>
                        <div className='product-en-wrap-body'>
                            <p className='product-en-text'>
                            Help enterprise users to adopt, use, and manage the cloud well.<br/>
                            Full lifecycle management services: consulting planning/migration services/operation and maintenance management services/continuous optimization on the cloud.<br/>
                            Cloud agent services: partner recruitment/high commission policy/full service support.<br/>
                            Cloud dedicated line services: cloud dedicated line/multi-cloud interconnection dedicated line/short delivery cycle/interconnect with major public cloud platforms at home and abroad.<br/>
                            Value-added services: cloud security (DDoS high defense, level protection)/cloud communication (Cloud SMS, IoT)/cloud meeting.
                            </p>
                        </div>
                    </div>
                    <div className='product-en-wrap'>
                        <div className='product-en-title '>
                            <img  src={ productIcons5} alt="" />
                            {/* <img className='name' src={ productTitle5} alt="" /> */}
                        </div>
                        <div className='product-en-wrap-body flex-wrap' >
                            <div className='text-content'>
                                <p className='product-en-text'>
                                ND Cloud Bare Metal: No virtualization performance loss, exclusive server computing power and storage space,<br/>
                                satisfying the needs of core application scenarios for high performance, stability, and security.<br/>
                                GPU Computing Power: Provides a variety of GPU models such as A100/A800/H800, creating a powerful and scalable GPU-accelerated service platform for AI, high-performance computing, rendering, and other scenarios.<br/>
                                <span className='showMobile'>
                                One-stop Operation and Maintenance Management Outsourcing: Assuming corresponding IT service quality and management performance for customers, driving customers to comprehensively improve the overall IT service management level.
                                </span>
                                </p>
                            </div>
                            <div className='product-en-service'>
                                <img className='name showMobile' src={ serviceImg} alt="" />
                                <img className='name hideMobile' src={ serviceMobileImg} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
             </div>
        </section>
    )
}
export default NdProduct