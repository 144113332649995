
import NdLayout from "@/components/NdLayout"
import './index.scss'
const items= [
    {
       label: 'Home',
      key: '/en'
    },
    {
       label: 'Products and Services',
      key: '/en/product'

    },
    { 
      label: 'About Us',
      key: '/en/about'
    },
  ]


const EnLayout = ()=>{
    return (
        <NdLayout NdLanguage="en"  items={items} />
    )   
}

export default EnLayout