import Home from "@/pages/Home"
import partnerImg  from "@/assets/img/en/Partners.png"
import serviceImg  from "@/assets/img/en/Global_services.png"
import DaystarImg  from "@/assets/img/en/Rising_star.png"
import  daystarTitle from '@/assets/img/daystar_title.png'
import StrategyImg  from "@/assets/img/en/Strategic_partners.png"
import CooperateImg  from "@/assets/img/en/Cooperation_application.png"

const bannerTitle =  "ND Cloud is a one-stop global cloud resource service provider for emerging industries such as \n Web3, Metaverse, AI, etc., strategically supported by Gosun HK and iPollo."
const language = 'en'

const NdHome = ()=>{
    return (
        <Home
            bannerTitle={bannerTitle}
            language={language}
            partnerImg={partnerImg}
            serviceImg={serviceImg}
            DaystarImg={DaystarImg}
            daystarTitle={daystarTitle}
            CooperateImg={CooperateImg}
            StrategyImg={StrategyImg}
         />
    )
}

export default NdHome