import { CnLayout,EnLayout } from "@/Layout";
// 二级路由

import  { NdHome,NdAbout } from '@/language/zh'
import  { NdHomeEn,NdAboutEn,NdProductEn } from '@/language/en'
import Product from '@/pages/Product'

// 配置路由实例
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: '/',
        element: <CnLayout/>,
        children:[
            { 
                path: '/',
                index: true,
                element: <NdHome/>,
            },
            {
                path: '/about',
                element: <NdAbout/>
            },
            {
                path: 'product',
                element: <Product/>
            }
        ]
    },
    {
        path: '/en',
        element: <EnLayout/>,
        children:[
            { 
                path: '/en',
                index: true,
                element: <NdHomeEn/>,
            },
            {
                path: 'about',
                element: <NdAboutEn/>
            },
            {
                path: 'product',
                element: <NdProductEn/>
            }
        ]
    }
])  
export default router