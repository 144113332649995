// import './index.scss'
 
 import starIcon from '@/assets/img/star_icon.png'
 import starA from '@/assets/img/1.png'
 import starB from '@/assets/img/2.png'
 import starC from '@/assets/img/3.png'
 import starD from '@/assets/img/4.png'
 import starE from '@/assets/img/5.png'
 import starF from '@/assets/img/6.png'

 const items = [
    { img_url: starA,
        text:{
            zh: '最高价值20万美金的公有云大礼包',
            en: 'A public cloud gift pack worth up to 200,000 US dollars'
        }
    },
    { img_url: starB,
        text:{
            zh: 'Web3行业媒体资源支持',
            en: 'Web3 industry media resource support'
        }
    },
    { img_url: starC,
        text:{
            zh: '香港Web3相关资源申请与对接',
            en: 'Application and docking of Hong Kong Web3 related resources'
        }
    },
    { img_url: starD,
        text:{
            zh: '提供免费定制去中心化存储方案和存储服务',
            en: 'Provision of free customized decentralized storage solutions and storage services'
        }
    },
    { img_url: starE,
        text:{
            zh: 'Fil Orbit大使申请支持，活动经费赞助等',
            en: 'Support for Fil Orbit ambassador application, sponsorship of activity expenses, etc'
        }
    },
    { img_url: starF,
        text:{
            zh: '开通可训练的AI大模型平台',
            en: 'Opening of a trainable AI large model platform'
        }
    }
 ] 
function DaystarItems({ img_url,text,language}){
    return (
        <div className="daystar-item">
            <img className="daystar-logo" src={ img_url} alt="" />
            <div className='daystar-introduction'>
                <div className='text-wrap'>
                    <span className="text">{text[language] }</span>
                </div>
                <img className="daystar-icon" src={ starIcon} alt="" />
                
            </div>
        </div>
    )
}

function Daystar({language,DaystarImg,daystarTitle}){
    return(
       <section className="main-daystar">
            <div className='container'>
                 <div className='daystar-header'>
                    <img src={ DaystarImg} alt="" />
                 </div>
                 <div className="daystar-title">
                    {
                        language === 'zh' ? 
                            <img src={ daystarTitle} alt="" />
                        : 
                            <span className='tc ' >In-depth analysis of industry builder development needs to reduce costs <br/> and increase efficiency</span>
                    }
                   
                 </div>
                <div className='daystar-content'>
                    <div className="daystar-list">
                        {
                            items.map((item,index)=>{
                                return(
                                     <DaystarItems
                                        key={index}
                                        img_url={item.img_url}
                                        text={item.text}
                                        language={language}
                                     />
                                   
                                )
                            })
                        }
                    </div>
                </div>
            </div>
       </section>
    )
}
export default Daystar