
//  import './index.scss'
 import globalImg from '@/assets/img/global_bg.png'
 import hrIcon from '@/assets/img/hr.png'

 import serviceA from '@/assets/img/service1.png'
 import serviceB from '@/assets/img/service2.png'
 import serviceC from '@/assets/img/service3.png'

 const items = [
    { img_url: serviceA,
        id: 1,
        text:{
            zh: '全球范围与规模',
            en: 'Global Range and Scale'
        }
    },
    { img_url: null,text:''},
    { img_url: serviceB,
        id: 2,
        text:{
            zh: '开发人员友好 \n高级安全性',
            en: 'Developer-friendly,\n Advanced Security'
        }
    },
    { img_url: null,text:''},
    { img_url: serviceC,
        id: 3,
        text:{
            zh: '7×24专属技术支持',
            en: '7x24 Exclusive \n Tezhnical Support'
        }
  }
 ] 
function ServiceItems({ img_url,text,id,language}){

    return (
        <div className="service-item">
            <img className="service_logo" src={ img_url} alt="" />
            <div className='service-introduction'>
                <span className="number">{ '0'+id }</span>
                <span className="text white-space tc">{ text[language] }</span>
                
            </div>
        </div>
    )
}

function GlobalService({language,serviceImg}){
    return(
       <section className="main-service">
            <div className='container'>
                 <div className='service-header'>
                    <img src={ serviceImg} alt="" />
                 </div>
                 <div className="service-global">
                    <img src={ globalImg} alt="" />
                 </div>
                <div className='service-content'>
                    <div className="service-list">
                        {
                            items.map((item,index)=>{
                                return(
                                    item.img_url ?
                                     <ServiceItems
                                        key={index}
                                        index={index}
                                        img_url={item.img_url}
                                        text={item.text}
                                        id={item.id}
                                        language={language}
                                     />
                                     :(
                                        <img className="hr" key={index} src={ hrIcon } alt="" />
                                     )
                                )
                            })
                        }
                    </div>
                </div>
            </div>
       </section>
    )
}
export default GlobalService