import aboutLogo from '@/assets/img//en/about.png'
import About from '@/pages/About'

const language = "en"

const NdAbout = ()=>{
    return (
        <About language={language} aboutLogo={aboutLogo} />
    )
}
export default NdAbout