import './index.scss'
const aboutText = {
    zh:" ND Cloud是由Gosun HK和iPollo战略支持的面向Web3、元宇宙、AI等新兴行业的一站式全球性云资源服务商。在New Data时代，拥抱新兴产业，ND Cloud致力于提升云基础服务体验，深度解析行业Builder发展需求，帮助其降低成本，提供行业生态服务，为终将到来的范式迁移贡献力量。\nND Cloud提供包括Google Cloud、Huawei Cloud、Alibaba Cloud、AWS在内的主流云服务，依托Gosun HK多年的云服务资源、大数据产品研发与平台服务能力，再结合iPollo在Web3和元宇宙行业中的探索和资源积累，在云服务、专线、CDN、算力服务和IDC等业务领域助力各生态中的Web3和元宇宙项目实现跨越式发展，同时也为项目客户提供创新云端方案，实现多方共赢。",
    en: `ND Cloud is a one-stop global cloud resource service provider for emerging industries such as Web3, Metaverse, AI, etc., strategically supported by Gosun HK and iPollo.
    In the New Data era, embracing emerging industries, ND Cloud is committed to enhancing the cloud-based service experience, deeply analyzing the development needs of industry builders, helping them to reduce costs, providing industry ecosystem services, and contributing to the eventual paradigm shift.\n
    ND Cloud provides mainstream cloud services including Google Cloud, Huawei Cloud, Alibaba Cloud, AWS, etc. Relying on Gosun HK's years of cloud service resources, big data product research and development and platform service capabilities, combined with iPollo's exploration and resource accumulation in the Web3 and Metaverse industries, it promotes leapfrog development in various ecological areas such as cloud services, dedicated lines, CDN, computing services, and IDC, and also provides innovative cloud solutions for project customers to achieve multi-party wins.
    
    `
}
const aboutNameText = {
    zh: "联系我们",
    en: "Contact Us"
}

function About({aboutLogo,language}){
    return(
        <section className="main-about">
             <div className='container'>
                <div className='about-header'>
                    <img src={ aboutLogo} alt="" />
                </div>
                <div className='about-content'>
                    <div className='about-introduction white-space'>
                        { aboutText[language] }
                    </div>
                    <div className='about-contact'>
                        <span >
                            {/* 联系我们 */}
                            {aboutNameText[language]}
                        </span>
                    </div>
                   
                     <div className='about-email'>
                        <span>bd@ndcloud.io</span>
                     </div>
                </div>
             </div>
        </section>
    )
}
export default About