import { Menu } from 'antd'
import { useNavigate} from 'react-router-dom'

// import { findBgBykey } from '@/utils'

function MenuLayout({items,currentPage}){
    //  调用设置背景的方法
    // const handleButtonClick = useCallback((bg) => {
    //   changeBackground(bg);
    // },[changeBackground]);
    
    const selectedKey = currentPage

    const navigate = useNavigate();
    const onMenuClick = (route)=>{
        console.log('菜单被点击了',route)
        const path = route.key;
   
        navigate(path)
    }
    // 设置对应的背景图片
    // useEffect(()=>{
    //     handleButtonClick(findBgBykey(selectedKey,items) )
    // },[selectedKey,handleButtonClick,items])
    return(
        <Menu
            mode="horizontal"
            theme="dark"
            // defaultSelectedKeys={['/']}
            selectedKeys={selectedKey}
            onClick={ onMenuClick}
            items={items}
            style={{ height: '100%', borderRight: 0 }}
        >
        </Menu>
    )
}

export default MenuLayout   